import React, {useState} from 'react';
import { getTokenOfOwner } from '../../services/web3/web3';
// import { mintRadar } from '../../services/web3/web3';
import { newArtistInRadar } from '../../services/firebase/api';
import "./../../assets/icons/uicons/css/uicons-regular-rounded.css";
import "./TimeOut.scss";

const TimeOut = ({popup_timeout, setpopup_timeout, setpopup_congratulations, formData}) => {
	const closepopup3 = (e) => {
        e.preventDefault();
        setpopup_timeout(false);
      }
      const getTransitionResponse = async (e) => {
        e.preventDefault();
        const token = await getTokenOfOwner();
          if (token === "0") {
            alert("Please, don't leave the page, and try again in a few minutes");
          } else {
            const data = {
              artistName: formData.artistName,
              urlPortfolio: formData.urlPortfolio,
              tokenId: token
            }
            const firebase = await newArtistInRadar(data);
            console.log('firebase new artist: ' + firebase);
            setpopup_congratulations(true);
            setpopup_timeout(false);
            return;
          }
      }
return (
  <div id="absolute3">
    <div id={popup_timeout ? "timeOut" : "displayNone"}>
        <div id="timeOut__title">
            <p><strong>Please wait...</strong></p>
        </div>
        <div id="timeOut__text1">
            <p>The transaction may not be immediate due to the current state of the Blockchain.
			   Be patient and do not refresh or leave the main page.</p>
        </div>
        <div id="timeOut__text2">
            <p><strong>Wait for Metamask successful notification and press the button to receive your token id!</strong></p>
        </div>
		<div id="timeOut__button">
			<button onClick={getTransitionResponse}>GET MY TOKEN ID</button>
        </div>
    </div>
  </div>
  )
}
export default TimeOut