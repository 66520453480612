
import React, { useState, useRef, useEffect } from 'react';
import HardDiskMuseum from "../../assets/images/HardDiskMuseum.png";
import Discord from "../../assets/images/Discord.png";
import Radar from "../../assets/videos/Radar.mp4";
import Symbol1 from "../../assets/icons/721-logo-p.svg";
import Symbol2 from "../../assets/icons/symbol2.svg";
import "./LandingPage.scss";
import { getAllArtistInRadar } from '../../services/firebase/api';
import { getUserSuscriptionLeft } from '../../services/web3/web3';
import { RWebShare } from "react-web-share";

const LandingPage = ({ openpopup_mintNFT }) => {

    // const [timerDays, setTimerDays] = useState('00');
    // const [timerHours, setTimerHours] = useState('00');
    // const [timerMinutes, setTimerMinutes] = useState('00');
    // const [timerseconds, setTimerseconds] = useState('00');
    const [artists, setArtists] = useState([]);

    // let interval = useRef();

    // const startTimer = () => {
    //     // const countdownDate = new Date('Sep 01, 2022 00:00:00').getTime();

    //     interval = setInterval(() => {
    //         const now = new Date().getTime();
    //         const distance = countdownDate - now;
    //         const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    //         const hours = Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)));
    //         const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    //         const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    //         if (distance < 0) {
    //             clearInterval(interval.current);
    //         } else {
    //             setTimerDays(days);
    //             setTimerHours(hours);
    //             setTimerMinutes(minutes);
    //             setTimerseconds(seconds);
    //         }
    //     }, 1000);
    // };

    const getArtistsUrls = async () => {
        await getAllArtistInRadar((artists) => {
            setArtists(artists);
        });
    }

    useEffect(() => {
        // startTimer();
        getArtistsUrls();
        return () => {
            clearInterval(interval); //.current
        };
    }, []);

    return (
        <div id="landingPage">
            <div id="landingPage__header">
                <div id="landingPage__left">
                    <div id="landingPage__title">
                        <a target="_blank" rel="noreferrer" href="https://harddiskmuseum.com/" ><img src={HardDiskMuseum} alt=""></img></a>
                    </div>
                    <div id="landingPage__subtitle">
                        <h4>ARTIST RADAR</h4>
                    </div>
                    <div id="landingPage__text1">
                        <div id="landingPage__text1--top">
                            <p>Welcome to the <strong>Harddiskmuseum's</strong> Artist's Radar.</p>
                            <p>This space has a clear function:</p>
                        </div>
                        <div id="landingPage__text1--middle">
                            <p>- To collect new artistic talents to bring them to the Museum.</p>
                        </div>
                        <div id="landingPage__text1--down">
                            <p>Artists, curators, managers or gallerists can propose a portfolio/website for monthly viewing.</p>
                        </div>
                    </div>
                    <div id="landingPage__text2">
                        <h6>THANKS TO THIS NFT ACCESS YOU WILL NOT ONLY HAVE THE POSSIBILITY TO BE PART OF THE MUSEUM AND ITS EXHIBITIONS, BUT 
                            ALSO TO ENJOY OUR OFFLINE PROJECTS, SPECIAL INFORMATION RELATED WITH THE NEW MEDIA SECTOR AND THE VISIBILITY OF YOUR WORK FOR OUR PARTNERS AND CURATORS.</h6>
                    </div>
                    <div id="landingPage__text3">
                        <h6>Share the link to your work and it will be listed below.</h6>
                        <h6>Selected artist will be notified through our Discord channel.</h6>
                    </div>
                    <div id="landingPage__discord">
                        <a id="landingPage__image" target="_blank" rel="noreferrer" href="https://discord.gg/fmktXEnstN" ><img src={Discord} alt=""></img></a>
                    </div>
                </div>
                <div id="landingPage__right">
                    <video autoPlay loop muted> <source src={Radar} type="video/mp4" /></video>
                </div>
            </div>
            <div id="landingPage__main">
                <div id="landingPage__open">
                    <h3>RADAR OPEN</h3>
                </div>
                {/* <div id="landingPage__timer">
                    <h2>{timerDays}:{timerHours}:{timerMinutes}:{timerseconds}</h2>
                </div> */}
                <div id="landingPage__access">
                    <h3>Get your access here:</h3>
                </div>
                <div id="landingPage__buttons">
                    <div id="landingPage__nft">
                        <div id="landingPage__button1">
                            <button onClick={openpopup_mintNFT}>MINT YOUR PORTFOLIO</button>
                        </div>
                        <div id="landingPage__matic">
                            <p>23 MAT</p>
                        </div>
                    </div>
                    {/* <div id="landingPage__subscription">
                    <div id="landingPage__button2">
                    <a href="/"><button>SUBSCRIPTION ACCES</button></a>
                    </div>
                    <div id="landingPage__euro">
                        <p>25 €</p>
                    </div>
                </div> */}
                </div>
                <div id="landingPage__conditions">
                    <p>We recommend sharing websites, portfolios in .pdf format, video teaser or profiles on social networks where the artist's
                        work is shown in detail, thus making it easier for the curatorial team to evaluate the work.</p>
                </div>

                <div id="landingPage__line"></div>
                <div id="landingPage__portfolio">
                    <h2>ARTIST PORTFOLIO LIST</h2>
                </div>
                <div id="landingPage__artists">
                    {artists.map((artist, index) => (
                        <div id="landingPage__links" key={index}>
                            <a  href={artist.urlPortfolio} target="_blank" rel="noreferrer">
                                {artist.urlPortfolio}
                            </a>
                        </div>
                       
                    ))}
                </div>
				<div id="landingPage__button2">
					<RWebShare data={{ text: "This is the Artist Radar from Harddiskmuseum", url: "http://radar.harddiskmuseum.com/", title: "HARDDISKMUSEUM RADAR", }} onClick={() => console.log("shared successfully!")} >
				 		<button>SHARE</button>
					</RWebShare>
				</div>
            </div>
            <div id="landingPage__footer">
                <a target="_blank" rel="noreferrer" href="https://721.harddiskmuseum.com/" ><img id="landingPage__symbol1" src={Symbol1} alt="" /></a>
                <div id="landingPage__721">
                    <div id="landingPage__721--title">
                        <h6>721</h6>
                    </div>
                    <div id="landingPage__721--line"></div>
                    <div id="landingPage__721--description">
                        <li id="landingPage__contact"><a target="_blank" rel="noreferrer" href="mailto:hola@harddiskmuseum.com" >Contact Us</a></li>
                        <li id="landingPage__whitepaper"><a target="_blank" rel="noreferrer" href="https://www.dropbox.com/s/12agvd7r0g4x65g/721_WP_WEB.pdf?dl=0" >Whitepaper</a></li>
                    </div>
                </div>
                <a target="_blank" rel="noreferrer" href="https://harddiskmuseum.com/" ><img id="landingPage__symbol2" src={Symbol2} alt=""></img></a>
            </div>
        </div>
    )
}

export default LandingPage