import RadarNFTFactory from './smartcontract/HDMRadar.json';
import { contractAddress } from './smartcontract/config.js';
import {isMobile} from 'react-device-detect';

const Web3 = require("web3");
const pinataSDK = require('@pinata/sdk');
const pinata = pinataSDK('0025c542af9d90e2ffa5', '81dfb7963276e0086b50376a4d84d2b777aeb10eaa7c923082f3aa40c0366740');

let web3Metamask;
let metamaskAccounts;
let metamaskAccount;



// ---web3 connection---
export const web3Connect = async () => {
	console.log('mobile check', isMobile);

  if (isMobile && window.location !== 'https://metamask.app.link/dapp/radar.harddiskmuseum.com') {
	  window.location = 'https://metamask.app.link/dapp/radar.harddiskmuseum.com';
  }
  if (typeof window !== "undefined") {
    console.log("if window");
    await window.ethereum.request({ method: "eth_requestAccounts" });
    web3Metamask = new Web3(window.ethereum);
    // const minter = new Web3(window.ethereum);
    metamaskAccounts = await web3Metamask.eth.getAccounts();
    metamaskAccount = metamaskAccounts[0];
    // console.log("minter cuenta fileform: ", metamaskAccount);
  }
};


export const getPinataSuscriptionPrice = async () => {
  try {
    await web3Connect();
  } catch (err) {
    alert("Error connecting to metamask");
  }
  try {
    pinata.testAuthentication().then((result) => {
      //handle successful authentication here
      console.log("API Keys Pinata", result);
    }).catch((err) => {
      //handle error here
      console.log(err);
    });
  } catch (e) {
    console.lof('Pinata test authentication failed: ', e);
  }

  const JSONPinata = {
    name: "HDMRadar",
    description: "This is an Access NFT to Harddiskmuseum",
    artist: "SLMN",
    image: "https://gateway.pinata.cloud/ipfs/QmaRGdHA7u5JspvbWMxf61mgRFpTLLBeqtfG5wQdp8d56P",
    symbol: "HDMR",
    minter: metamaskAccount
  };

  const optionsJSONPinata = {
    pinataMetadata: {
      name: "artistRadar",
    }
  };
  let url;

  await pinata.pinJSONToIPFS(JSONPinata, optionsJSONPinata).then((result) => {
    //handle results here
    console.log(result.IpfsHash);

    url = `https://gateway.pinata.cloud/ipfs/${result.IpfsHash}`;

  }).catch((err) => {
    //handle error here
    console.log(err);
  });

  console.log("Url Pinata", url);
  try {
    await web3Connect();
  } catch (err) {
    alert("Error connecting to metamask");
  }

  const minterContract = await new web3Metamask.eth.Contract(
    RadarNFTFactory.abi,
    contractAddress
  );


  let subscriptionPrice = await minterContract.methods.getSubscriptionPrice().call();

  console.log("Subscription Price", subscriptionPrice);
  if (subscriptionPrice) {
    return { url, subscriptionPrice };
  } else {
    return null;
  }

}

export const mintRadar = async (data) => {

  try {
    await web3Connect();
  } catch (err) {
    alert("Error connecting to metamask");
  }

  const minterContract = await new web3Metamask.eth.Contract(
    RadarNFTFactory.abi,
    contractAddress
  );

  let transaction = await minterContract.methods._mintRadarToken(data.url).send({
    from: metamaskAccount,
    value: data.subscriptionPrice,
    gasPrice: 48600000000,
  });

  console.log('transaction', transaction);
  
  let tokenIdMinted = await transaction.events.Transfer.returnValues[2];

  if (tokenIdMinted) {
    return tokenIdMinted;
  } else {
    return null;
  }
};

export const getTokenOfOwner = async () => {
  try {
    await web3Connect();
  } catch (err) {
    alert("Error connecting to metamask");
  }

  const minterContract = await new web3Metamask.eth.Contract(
    RadarNFTFactory.abi,
    contractAddress
  );

  let transaction = await minterContract.methods.returnOwnerToken().call({
    from: metamaskAccount,
  });

  if(transaction) {
    return transaction;
  }
}


export const getUserSuscriptionLeft = async () => {
  try {
    await web3Connect();
  } catch (err) {
    alert("Error connecting to metamask");
  }

  const minterContract = await new web3Metamask.eth.Contract(
    RadarNFTFactory.abi,
    contractAddress
  );

  try {
    let transaction = await minterContract.methods.getUserSubscriptionDurationLeft().call({
      from: metamaskAccount
    });
    if(transaction) {
      return transaction;
    }
  } catch (err) {
    console.log(err);
  }




  
}
export const withdrawFees = async () => {
  try {
    await web3Connect();
  } catch (err) {
    alert("Error connecting to metamask");
  }

  const minterContract = await new web3Metamask.eth.Contract(
    RadarNFTFactory.abi,
    contractAddress
  );

  let transaction = await minterContract.methods.withdrawFees().send({
    from: metamaskAccount,
    gasPrice: 35000000000,
  });



  if(transaction) {
    return transaction;
  }
}
export const unsubscribe = async (tokenId) => {
  try {
    await web3Connect();
  } catch (err) {
    alert("Error connecting to metamask");
  }

  const minterContract = await new web3Metamask.eth.Contract(
    RadarNFTFactory.abi,
    contractAddress
  );

  let transaction = await minterContract.methods.unsubscribe(tokenId).send({
    from: metamaskAccount,
    gasPrice: 35000000000,
  });



  if(transaction) {
    return transaction;
  }
}