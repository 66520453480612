import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyBJu-eC0nX-L5no9DAwoqTW_ZrbC-XNevc",
  authDomain: "harddiskmuseum.firebaseapp.com",
  databaseURL: "https://harddiskmuseum.firebaseio.com",
  projectId: "harddiskmuseum",
  storageBucket: "harddiskmuseum.appspot.com",
  messagingSenderId: "556880766528",
  appId: "1:556880766528:web:111bdbf1d8ca69247521a5",
  measurementId: "G-FPW300T0FZ"
};

const app = firebase.initializeApp(firebaseConfig);

export const analytics = app.analytics();

export const db = app.firestore();