import { db } from './config.js';
import {
    doc,
    getDoc,
  } from "firebase/firestore";

export async function newArtistInRadar(data) {
    try {
        const doc = db.collection("radar").doc();
        await doc.set(data).then(() => {
            const id = doc.id;
            return id;
        });
    } catch (err) {
        return err;
    }
}

export async function getAllArtistInRadar(callback) {
    try {
        db.collection("radar").onSnapshot((snapshot) => {
            const docs = [];
            snapshot.forEach((doc) => {
                const data = doc.data();
                docs.push({
                    ...data,
                    id: doc.id,
                });
            });
            callback(docs);
        });
    } catch (err) {
        callback(null, err);
    }
}

