import React from 'react';
import Discord from "../../assets/images/Discord.png"; 
import "./../../assets/icons/uicons/css/uicons-regular-rounded.css";
import "./Congratulations.scss";

const Congratulations = ({popup_congratulations, setpopup_congratulations}) => {
    const closepopup2 = (e) => {
        e.preventDefault();
        setpopup_congratulations(false);
      }
  return (
  <div id="absolute2">
    <div id={popup_congratulations ? "congratulations" : "popup2Display"}>
        <div id="congratulations__close">
            <i onClick={closepopup2} className="fi-rr-cross-circle"></i>
        </div>
        <div id="congratulations__title">
            <h2>CONGRATULATIONS!</h2>
        </div>
        <div id="congratulations__text1">
            <p>You are now listed in the Harddiskmuseum Artist Radar, 
               we hope that you will eventually be part of the master pieces at the Museum as well.</p>
        </div>
        <div id="congratulations__text2">
            <p>Remember Join ou Discord Channel to be notified.</p>
        </div>
        <div id="congratulations__discord">
        <a id="landingPage__image" target="_blank" rel="noreferrer" href="https://discord.gg/fmktXEnstN" ><img src={Discord} alt=""></img></a>
        </div>
    </div>
  </div>
  )
}

export default Congratulations