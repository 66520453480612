import React, { useState, useEffect } from 'react';
import { getPinataSuscriptionPrice, getTokenOfOwner, getUserSuscriptionLeft, mintRadar, unsubscribe, withdrawFees } from '../../services/web3/web3';
import { newArtistInRadar } from '../../services/firebase/api';
import "./../../assets/icons/uicons/css/uicons-regular-rounded.css";
import "./MintNFT.scss";
// import ReactGA from 'react-ga';
// ReactGA.initialize('G-J6MD864TSD');

const MintNFT = ({popup_mintNFT, setpopup_mintNFT, setpopup_congratulations, setpopup_timeout, formData, setFormData}) => {
      
      const [minting, setMinting] = useState(false);
      const handleChange = (event) => {
        let value = event.target.value;
        let name = event.target.name;
      
        setFormData((prevalue) => {
          return {...prevalue, [name]: value}
        })
      }

      const closepopup1 = (e) => {
        e.preventDefault();
        setpopup_mintNFT(false);
      }

      const mintRadarNft = async (e) => {
        e.preventDefault();
        setMinting(true);
        if(formData.artistName ===  "" || formData.urlPortfolio === ""){
          setMinting(false);
          return alert("Please, fulfill the form with your name and portfolio URL before minting");
        }
        try {
          const suscriptionData = await getPinataSuscriptionPrice();
          const tokenIdMint = await mintRadar(suscriptionData);
          if (tokenIdMint) {
            const data = {
              artistName: formData.artistName,
              urlPortfolio: formData.urlPortfolio,
              tokenId: tokenIdMint
            }
            const firebase = await newArtistInRadar(data);
            console.log('firebase new artist: ' + firebase)
            setMinting(false);
            setpopup_mintNFT(false);
            setpopup_congratulations(true);
          }
        } catch (e) {
        //   console.log('error minting: ', e.message);
          if(e.message.includes('Transaction was not mined within 50 blocks')){
            setMinting(false);
            setpopup_timeout(true)
            setpopup_mintNFT(false);
            console.log('redirect to new popup')
          } else if(e.message.includes('Transaction has been reverted by the EVM')){
            setMinting(false);
            setpopup_mintNFT(false);
            alert('Sorry, you can mint just one Radar NFT')
          
          } else if(e.message.includes('You might also see this error if you are not using the correct ABI for the contract you are retrieving data from')){
            setMinting(false);
            setpopup_mintNFT(false);
            alert('Please, make sure you are connected with Polygon Network before minting.')
          
          } else if(e.message.includes('User denied transaction signature')){
            setFormData({artistName: "", urlPortfolio: ""})
            const input1 = document.getElementById('input1');
            const input2 = document.getElementById('input2');
            input1.value = "";
            input2.value = "";

            setMinting(false);
            setpopup_mintNFT(false);
          } else {
            setMinting(false);
            setpopup_mintNFT(false);
            alert('Sorry, we have had a problem. Please try again in a few minutes.')
          }
          return e;
        }
      }

  return (
  <div id="absolute1">
    <form id={popup_mintNFT ? "mintNFT" : "popup1Display"}>
        <div id="mintNFT__close">
            <i onClick={closepopup1} className="fi-rr-cross-circle"></i>
        </div>
        <div id="mintNFT__title">
            <h2>GET YOUR ACCESS NFT</h2>
        </div>
        <div id="mintNFT__name">
            <input id="input1" type="text" name="artistName" placeholder="Artist name" onChange={handleChange}></input>
        </div>
        <div id="mintNFT__url">
            <input id="input2" type="text" name="urlPortfolio" placeholder="Portfolio URL" onChange={handleChange}></input>
        </div>
        <div id="mintNFT__mint">
            <input type="submit" name="mintNFT" disabled={minting ? true : false} value={minting ? "MINTING" : "MINT NFT"} onClick={mintRadarNft}></input>
        </div>
    </form>
  </div>
  )
} 
export default MintNFT