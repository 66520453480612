
import React, { useState, useEffect } from "react";
import LandingPage from "./components/LandingPage/LandingPage";
import MintNFT from "./components/MintNFT/MintNFT.js";
import Congratulations from "./components/Congratulations/Congratulations";
import TimeOut from "./components/TimeOut/TimeOut";
import './App.scss';
import ReactGA from 'react-ga';
ReactGA.initialize('G-J6MD864TSD');

function App() {

  const [popup1, setPopup1] = useState(false);
  const [popup2, setPopup2] = useState(false);
  const [popup3, setPopup3] = useState(false);
  const [fullName, setFullName] = useState({
    artistName: '',
    urlPortfolio: '',
  })


  const openPopup1 = () => {
    setPopup1(true);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])
  

  return (
    <div className="App">
      <MintNFT popup_mintNFT={popup1} setpopup_mintNFT={setPopup1} setpopup_congratulations={setPopup2} setpopup_timeout={setPopup3} formData={fullName} setFormData={setFullName} />
      <Congratulations popup_congratulations={popup2} setpopup_congratulations={setPopup2} />
      <TimeOut setpopup_congratulations={setPopup2} popup_timeout={popup3} setpopup_timeout={setPopup3} formData={fullName} />
      <LandingPage openpopup_mintNFT={openPopup1} />
    </div>
  );
}

export default App;
